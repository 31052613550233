<template>
  <div class="mainButton position-relative">
    <div class="position-absolute top-50 start-50 translate-middle">
      <div class="container">
        <div class="row">
          <router-link
            class="col-lg-6"
            :to="'/employee/ATEL/create/' + $route.params._id + '/accident'"
          >
            <div
              id="AT1"
              class="ATButton text-dark border border-2 rounded my-2 d-flex align-items-center justify-content-center"
            >
              <h4 class="fw-bold">Accidente Laboral</h4>
            </div>
          </router-link>
          <router-link
            class="col-lg-6"
            :to="'/employee/ATEL/create/' + $route.params._id + '/disease'"
          >
            <div
              id="AT2"
              class="ATButton text-dark border border-2 rounded my-2 d-flex align-items-center justify-content-center"
            >
              <h4 class="fw-bold">Enfermedad Laboral</h4>
            </div>
          </router-link>
        </div>
      </div>
      <router-link
        :to="'/employee/ATEL/' + $route.params._id"
        class="fs-6 fw-bold text-dark border border-2 rounded mx-2 py-3 d-flex justify-content-center"
      >
        Regresar
      </router-link>
    </div>
  </div>
</template>

<style lang="css" scoped>
.mainButton {
  height: 100vh;
  width: 100vw;
}
.ATButton {
  width: 450px;
  height: 300px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  opacity: 0.5;
  transition: 0.3 ease-in-out;
  cursor: pointer;
}
.ATButton:hover {
  opacity: 1;
}
#AT1 {
  background-image: url("/img/background/jj-ying-7JX0-bfiuxQ-unsplash.jpg");
}
#AT2 {
  background-image: url("/img/background/luca-bravo-alS7ewQ41M8-unsplash.jpg");
}
@media only screen and (max-width: 1400px) {
  .ATButton {
    width: 300px;
    height: 200px;
  }
}
@media only screen and (max-width: 600px) {
  .ATButton {
    opacity: 1;
  }
}
</style>
